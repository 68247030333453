var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"position":"absolute","top":"0px","right":"0px","bottom":"0px","left":"0px"},attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"d-print-none",attrs:{"fixed":""}},[(_vm.project.canEdit)?_c('div',{staticClass:"d-print-none"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchView.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.view === "cards" ? "mdi-file-document" : "")+" "+_vm._s(_vm.view === "document" ? "mdi-view-column-outline" : "")+" ")])],1)]}}],null,false,1724732891)},[_vm._v(" "+_vm._s(_vm.view === "cards" ? _vm.$t("learningDesign.viewDocument") : "")+" "+_vm._s(_vm.view === "document" ? _vm.$t("learningDesign.viewTable") : "")+" ")])],1):_vm._e(),_c('div',{staticClass:"d-print-none"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showComments = !_vm.showComments}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.showComments ? "mdi-comment" : "mdi-comment-off")+" ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.showComments ? _vm.$t("learningDesign.hideComments") : _vm.$t("learningDesign.showComments"))+" ")])],1),_c('div',{staticClass:"d-print-none"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.showTimeline = !_vm.showTimeline}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.showTimeline ? "mdi-timeline-clock" : "mdi-timeline-remove")+" ")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.showTimeline ? _vm.$t("learningDesign.hideTimeline") : _vm.$t("learningDesign.showTimeline"))+" ")])],1),(_vm.view === 'document' && _vm.showComments)?_c('div',{staticClass:"d-print-none"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.sortComments = !_vm.sortComments}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.sortComments ? "mdi-sort-descending" : "mdi-sort-ascending")+" ")])],1)]}}],null,false,23141339)},[_vm._v(" "+_vm._s(_vm.sortComments ? _vm.$t("learningDesign.sortCommentsDesc") : _vm.$t("learningDesign.sortCommentsAsc"))+" ")])],1):_vm._e(),_c('div',{staticClass:"d-print-none"},[(_vm.project.canEdit !== 88)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","small":""},on:{"click":_vm.showDialogInviteEmail}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-supervisor")])],1)]}}],null,false,1829820005)},[_vm._v(" "+_vm._s(_vm.$t("learningDesign.participants"))+" ")]):_vm._e()],1),(_vm.isCourseOwner)?_c('div',{staticClass:"d-print-none"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.downloadLogsFile.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-notebook")])],1)]}}],null,false,57590306)},[_vm._v(" "+_vm._s(_vm.$t("learningDesign.downloadLogsFile"))+" ")])],1):_vm._e(),_c('v-divider',{staticClass:"mx-4 d-print-none",attrs:{"vertical":""}})],1),(_vm.project.id)?_c('div',{class:{
      project: true,
      docview: _vm.view === 'document' || !_vm.project.canEdit,
    }},[(_vm.view === 'cards' && _vm.project.canEdit)?_c('card-lists',{key:'card-list' + _vm.historyId,attrs:{"project":_vm.project,"timeline":_vm.showTimeline}}):_vm._e(),(_vm.view === 'document' || !_vm.project.canEdit)?_c('document-view',{key:'document-view' + _vm.historyId,attrs:{"projectId":_vm.project.id,"timeline":_vm.showTimeline}}):_vm._e()],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":_vm.notification.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":_vm.notification.colors[_vm.notification.type] || _vm.notification.colors.normal,"text":""},on:{"click":function($event){_vm.notification.show = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.notification.show),callback:function ($$v) {_vm.$set(_vm.notification, "show", $$v)},expression:"notification.show"}},[_vm._v(" "+_vm._s(_vm.notification.text)+" ")]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogInviteEmail),callback:function ($$v) {_vm.dialogInviteEmail=$$v},expression:"dialogInviteEmail"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2",attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.$t("learningDesign.participants"))+" ")]),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.project.users),function(u){return _c('v-list-item',{key:u.id,attrs:{"ripple":false}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.getUserNameFromAccess(u))+" "),_c('br'),_vm._v(_vm._s(u.roles.map(function (e) { return _vm.$t(("roles." + e)); }).join(", "))+" ")]),(_vm.canRemove(u))?_c('v-list-item-icon',[_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dense":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t(("status." + (u.status))))),_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("learningDesign.deleteParticipant")))]),_c('v-card-text',{staticClass:"pl-0 ml-0 mr-0 pr-0"},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("buttons.rejectDelete"))+" ")])],1),_c('v-list-item',{attrs:{"color":"red"},on:{"click":function($event){return _vm.removeParticipant({
                              aid: u.id,
                              redirect: u.userId === _vm.user.id,
                              access: u,
                            })}}},[_c('v-list-item-title',{attrs:{"color":"red"}},[_c('span',{staticStyle:{"color":"red !important"}},[_vm._v(" "+_vm._s(_vm.$t("buttons.acceptDelete"))+" ")])])],1)],1)],1)],1)],1)],1):_vm._e()],1)}),1)],1)],1),(_vm.project.canEdit)?_c('v-card-text',{staticClass:"mb-0 pb-0"},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',[_c('v-list-item',{attrs:{"inactive":"","ripple":false}},[(_vm.project.participants.length === 0)?_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('learningDesign.newParticipantEmail'),"append-icon":"mdi-email"},model:{value:(_vm.emailForInvite),callback:function ($$v) {_vm.emailForInvite=$$v},expression:"emailForInvite"}}):_vm._e(),(_vm.project.participants.length > 0)?_c('v-combobox',{attrs:{"dense":"","label":_vm.$t('learningDesign.newParticipantOrEmail'),"items":_vm.project.participants,"append-icon":"mdi-email","item-text":"label"},model:{value:(_vm.emailForInvite),callback:function ($$v) {_vm.emailForInvite=$$v},expression:"emailForInvite"}}):_vm._e()],1)],1)],1),_c('v-expand-transition',[(!_vm.addTeacherDisabled)?_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"dense":"","small":""},model:{value:(_vm.InviteMainRole),callback:function ($$v) {_vm.InviteMainRole=$$v},expression:"InviteMainRole"}},[_c('v-radio',{attrs:{"dense":"","small":"","value":"viewer","label":_vm.$t('learningDesign.inviteViewer')}}),_c('v-radio',{attrs:{"dense":"","small":"","value":"editor","label":_vm.$t('learningDesign.inviteEditor')}})],1)],1),_c('v-col',[_c('v-switch',{attrs:{"dense":"","small":"","label":_vm.$t('learningDesign.inviteCommenter')},model:{value:(_vm.InviteCanCommenter),callback:function ($$v) {_vm.InviteCanCommenter=$$v},expression:"InviteCanCommenter"}})],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.project.canEdit)?_c('v-card-actions',{staticClass:"mt-0 pt-0 pb-4 justify-center"},[_c('v-btn',{attrs:{"small":"","color":"primary","text":"","disabled":_vm.addTeacherDisabled ||
            !_vm.InviteMainRole ||
            _vm.InviteMainRole.length === 0},on:{"click":function($event){return _vm.sendInvitation([
              _vm.InviteMainRole,
              _vm.InviteCanCommenter ? 'commenter' : '' ])}}},[_vm._v(" "+_vm._s(_vm.$t("invites.send"))+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }