var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!(_vm.cardType !== 'teaching-design' && _vm.fixed))?_c('div',{class:{
    'list-card': true,
    'dragg-item': _vm.fixed !== true,
    'card-fixed': _vm.fixed,
  },style:({
    'min-height': _vm.type === 'teaching-design' ? '50px' : '24px',
    'border-left-color': _vm.getColorByType(_vm.item.type),
  }),attrs:{"id":_vm.id}},[_c('div',{class:{ 'card-content': true }},[(_vm.type === 'teaching-design' && _vm.fixed !== true)?_c('v-btn',{staticStyle:{"float":"right"},attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.swapItem.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-swap-horizontal")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.item.title)+" "),(_vm.timeline && (_vm.item._modifiedAt || _vm.item._modifiedBy))?_c('p',{staticClass:"text-caption mt-4"},[_vm._v(" Modified"),_c('br'),_vm._v(" "+_vm._s((_vm.item._modifiedAt && ("at " + (_vm.item._modifiedAt))) || "")),(_vm.item._modifiedAt)?_c('br'):_vm._e(),_vm._v(" "+_vm._s((_vm.item._modifiedBy && ("by " + (_vm.item._modifiedBy))) || "")+" ")]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }