var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"board"},[_c('draggable',{staticClass:"list-draggable",attrs:{"draggable":".dragg-item","drag-class":"dragging","ghost-class":"ghost"},on:{"update":_vm.colMoved},model:{value:(_vm.project.data.lists),callback:function ($$v) {_vm.$set(_vm.project.data, "lists", $$v)},expression:"project.data.lists"}},_vm._l((_vm.project.data.lists),function(list){return _c('div',{key:list.id,class:{
          'list-top': true,
          'list-wrapper': true,
          'dragg-item': list.fixed !== true,
        },attrs:{"id":list.id}},[_c('div',{staticClass:"list",style:({
            'border-top-color': _vm.getColorByType(list.type),
          })},[_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("columns." + list.type).title)+" ")]),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-menu")])],1),(list.cards.length > 0)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"272","color":"blue","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"float":"right"},attrs:{"color":"primary","dark":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}],null,true)},[_c('div',[_c('ul',_vm._l((_vm.$t('columns.' + list.type).tooltips),function(tip,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(tip)+" ")])}),0)])]):_vm._e()],1),_c('div',{staticClass:"list-cards"},[_c('draggable',{attrs:{"draggable":".dragg-item","ghost-class":"ghost","move":_vm.check,"id":list.id,"group":"cards"},on:{"update":_vm.cardMoved,"add":_vm.cardMoved},model:{value:(list.cards),callback:function ($$v) {_vm.$set(list, "cards", $$v)},expression:"list.cards"}},[_vm._l((list.cards),function(id){return _c('card',{key:(id + "-" + _vm.updatesCount),ref:id,refInFor:true,attrs:{"id":id,"project":_vm.project,"type":list.type,"timeline":_vm.timeline},on:{"swapped":_vm.swapped},nativeOn:{"click":function($event){return _vm.editCard({ itemId: id, listId: list.id })}}})}),_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('v-fade-transition',[(list.cards.length === 0)?_c('div',{class:{
                      'tooltips-card': true,
                    },attrs:{"transition":"scroll-x-transition"}},[_c('ul',_vm._l((_vm.$t('columns.' + list.type)
                          .tooltips),function(tip,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(tip)+" ")])}),0)]):_vm._e()])],1)],2)],1),(list.fixed !== true)?_c('div',{staticClass:"card-composer-container"},[_c('v-btn',{attrs:{"small":"","dense":"","text":""},on:{"click":function () {
                  _vm.createNewItem(list);
                }}},[_c('v-text-field',{staticClass:"ml-1 mr-1",attrs:{"placeholder":_vm.getNewCardButtonNameByType(list.type),"color":_vm.getColorByType(list.type),"prepend-icon":"mdi-plus","dense":"","single-line":"","hide-details":"","disabled":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createNewItem(list)}},model:{value:(list.newItemName),callback:function ($$v) {_vm.$set(list, "newItemName", $$v)},expression:"list.newItemName"}})],1)],1):_vm._e()])])}),0),_c('router-view',{key:_vm.$route.path})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }